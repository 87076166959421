import React, { Component } from 'react';
import styled from 'styled-components';
import Nearby from '../../plugins/nearby';
import Obfuscate from 'react-obfuscate';
// Needed TweenMax as Anime was really choppy with the continuous updating
import { TweenMax } from 'gsap/all';
import * as helpers from '../../helpers';
import * as vars from '../../styles/vars';

export default class FancyLink extends Component {
  constructor(props) {
    super(props);
    this.distanceThreshold = props.distanceThreshold;
    this.linkLineInterval = props.linkLineInterval;

    this.linkRef = React.createRef();
  }

  componentDidMount() {
    this.linkUnderline = this.linkRef.current.querySelector('span');

    if (!helpers.isTouchEnabled()) {
      this.setupProximityLink();
    }
  }

  setupProximityLink = () => {
    new Nearby(this.linkRef.current, {
      onProgress: distance => {
        const scale = helpers.lineEq(
          this.linkLineInterval.from,
          this.linkLineInterval.to,
          this.distanceThreshold.max,
          this.distanceThreshold.min,
          distance
        );
        TweenMax.to(this.linkUnderline, 0.5, {
          ease: 'Expo.easeOut',
          scaleX: `${Math.max(
            Math.min(scale, this.linkLineInterval.to),
            this.linkLineInterval.from
          )}`,
        });
      },
    });
  };

  render() {
    // Need this so we can obfuscate the email address
    if (this.props.isEmail) {
      return (
        <StyledFancyLinkEmail ref={this.linkRef} {...this.props}>
          <Obfuscate email="hello@mikeebee.com" />
          <LinkUnderline />
        </StyledFancyLinkEmail>
      );
    }

    return (
      <StyledFancyLink
        href={this.props.href}
        ref={this.linkRef}
        {...this.props}
      >
        {this.props.children}
        <LinkUnderline />
      </StyledFancyLink>
    );
  }
}

FancyLink.defaultProps = {
  distanceThreshold: { min: 0, max: 100 },
  linkLineInterval: { from: 0, to: 1 },
};

const LinkUnderline = styled.span`
  background-color: #fff;
  bottom: -6px;
  display: inline-block;
  height: 6px;
  left: 0;
  position: absolute;
  transform-origin: 0 0;
  transform: scaleX(0);
  width: 100%;
`;

const StyledFancyLink = styled.a`
  border-bottom: 6px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  position: relative;
  text-decoration: none;
  display: inline-block;
  /* line-height: 1; */
`;

const StyledFancyLinkEmail = styled.div`
  display: inline-block;
  position: relative;

  a {
    border-bottom: 6px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    position: relative;
    text-decoration: none;
    display: inline-block;
    color: #fff;
    position: relative;
    text-decoration: none;
    line-height: 1.3;

    ${vars.browserHack.firefox`
      line-height: 1.1;
    `}

    ${vars.browserHack.edge`
      line-height: 1.1;
    `}
  }

  ${LinkUnderline} {
    bottom: 2px;

    ${vars.browserHack.firefox`
      bottom: 6px;
    `}

    ${vars.browserHack.edge`
      bottom: 6px;
    `}
  }

  ${vars.breakpoint.lg`
    ${LinkUnderline} {
      bottom: 2px;

      ${vars.browserHack.firefox`
        bottom: 10px;
      `}

     ${vars.browserHack.edge`
        bottom: 10px;
      `}
    }
  `}
`;
