/**
 * Used for JS breakpoints, not to be confused with
 * /styles/vars/breakpoints... although it probably will be 🤷🏻‍♂️
 */

import bp from '../styles/vars/bp';

export const xs = () =>
  typeof window !== 'undefined'
    ? window.matchMedia(`(max-width: ${bp.sm})`).matches
    : true;
export const sm = () =>
  typeof window !== 'undefined'
    ? window.matchMedia(`(min-width: ${bp.sm})`).matches
    : true;
export const md = () =>
  typeof window !== 'undefined'
    ? window.matchMedia(`(min-width: ${bp.md})`).matches
    : true;
export const lg = () =>
  typeof window !== 'undefined'
    ? window.matchMedia(`(min-width: ${bp.lg})`).matches
    : true;
export const xl = () =>
  typeof window !== 'undefined'
    ? window.matchMedia(`(min-width: ${bp.xl})`).matches
    : true;

export default {
  xs,
  sm,
  md,
  lg,
  xl,
};
