import React, { Component } from 'react';
import styled from 'styled-components';
import Segment from 'segment-js';
import anime from 'animejs';

import * as vars from '../../styles/vars';

export default class Loading extends Component {
  constructor() {
    super();

    this.shadow = React.createRef();
    this.path = React.createRef();
    this.wrapper = React.createRef();
  }

  componentDidMount() {
    this.setUpLoader();
  }

  handleLoaded = () => {
    anime.set(this.wrapper.current, {
      transformOrigin: '50% 50%',
    });

    anime({
      targets: this.wrapper.current,
      opacity: 0,
      scale: 0.9,
      duration: 300,
      easing: 'easeInOutQuad',
      complete: () => this.props.handleLoader(),
    });
  };

  setUpLoader = () => {
    this.segment = new Segment(this.path.current, 0, 0);

    // anime.set(this.path.current, {
    //   stroke: `${vars.colors.brand}`,
    // });

    anime({
      targets: this.wrapper.current,
      opacity: [0, 1],
      scale: [0.9, 1],
      duration: 300,
      easing: 'easeInOutQuad',
      complete: () => this.loaderCircleAnim.play(),
    });

    this.loaderCircleAnim = anime({
      targets: this.path.current,
      autoplay: false,
      keyframes: [
        {
          strokeDasharray: this.segment.strokeDasharray(0, '100%'),
          duration: 1200,
          stroke: `${vars.colors.brand}`,
        },
      ],
      easing: 'easeInOutQuad',
      loop: false,
      complete: () => this.handleLoaded(),
    });
  };

  render() {
    return (
      <Wrapper ref={this.wrapper} {...this.props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
          <path
            stroke="#DDD"
            strokeWidth="4"
            d="M2,30a28,28 0 1,0 56,0a28,28 0 1,0 -56,0"
            fill="none"
            fillRule="evenodd"
            ref={this.shadow}
          />
          <path
            stroke="transparent"
            strokeWidth="4"
            d="M2,30a28,28 0 1,0 56,0a28,28 0 1,0 -56,0"
            fill="none"
            fillRule="evenodd"
            ref={this.path}
          />
        </svg>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: calc(var(--vh, 1vh) * 90);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;

  ${vars.breakpoint.md`
    height: calc(var(--vh, 1vh) * 100);
  `}

  svg {
    width: 40px;
    position: relative;
    transform: rotate(270deg) scaleX(-1);
  }
`;
