import global from './global';

const fontSize = {
  xxxxLarge: '96px',
  xxxLarge: '48px',
  xxlarge: '40px',
  xlarge: '24px',
  large: '20px',
  medium: '18px',
  base: `${global.baseFontSize}`,
  small: '12px',
};

export default fontSize;
