const colors = {
  black: '#111',
  white: '#FFFFFF',
  iron: '#CBCBCB',
  monsoon: '#7b7b7b',
  cod: '#2F2F2F',
  greyLighter: '#d6d6d6',
  greyLight: '#9b9b9b',
  grey: '#444444',
  brand: '#9134FC',
};

export default colors;
