import colors from './colors';

const global = {
  transitionSpeed: '300ms',
  easing: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
  tapHighlightColor: 'rgba(255, 255, 255, 0)',
  baseFontColor: colors.grey,
  baseFontSize: '16px',
  baseFontFamily: 'biotifbook, sans-serif',
  headingFontFamily: 'biotifbold, sans-serif',
  serifFontFamily: 'adelle, sans-serif',
  radius: '8px',
  siteWidth: '1300px',
};

export default global;
