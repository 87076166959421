import { global, fontWeight, fontSize, colors } from './vars';

const typography = {
  headingXLarge: `
    font-size: ${fontSize.xxxxLarge};
    font-weight: ${fontWeight.normal};
    line-height: 1;
    color: ${colors.black};
  `,
  headingLarge: `
    font-size: ${fontSize.xxxLarge};
    font-weight: ${fontWeight.normal};
    line-height: 1.08;
    color: ${colors.black};
  `,
  headingBase: `
    font-size: ${fontSize.xxlarge};
    font-weight: ${fontWeight.normal};
    line-height: 1.2;
    color: ${colors.black};
  `,
  headingSmall: `
    font-size: ${fontSize.xlarge};
    font-weight: ${fontWeight.normal};
    line-height: 1.33;
  `,
  headingXSmall: `
    font-size: ${fontSize.large};
    font-weight: ${fontWeight.normal};
    line-height: 1.2;
  `,
  bodyLarge: `
    font-family: ${global.baseFontFamily};
    font-size: ${fontSize.xlarge};
    font-weight: ${fontWeight.normal};
  `,
  body: `
    font-family: ${global.baseFontFamily};
    font-size: ${fontSize.base};
    font-weight: ${fontWeight.normal};
    line-height: 1.6;
  `,
  bodySmall: `
    color: ${colors.greyLight};
    display: block;
    font-family: ${global.baseFontFamily};
    font-size: ${fontSize.small};
    font-weight: ${fontWeight.normal};
  `,
};

export default typography;
