import { css } from 'styled-components';

const browserHack = {
  firefox: (...args) =>
    css`
      @media screen and (min--moz-device-pixel-ratio: 0) {
        ${css(...args)};
      }
    `,
  edge: (...args) =>
    css`
      @supports (-ms-ime-align: auto) {
        ${css(...args)};
      }
    `,
};

export default browserHack;
