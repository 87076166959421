const spacing = {
  small: '0.5rem',
  base: '1rem',
  medium: '2rem',
  large: '3rem',
  xlarge: '4rem',
  xxlarge: '5rem',
};

export default spacing;
