import React, { Component } from 'react';
import { window } from 'browser-monads';
import * as helpers from '../../helpers';
import _ from 'lodash';

export default class SetVhUnitsOnTouchDevices extends Component {
  componentDidMount = () => {
    this.bindEvents();
    this.setContentSize();
    this.windowWidth = window.innerWidth;
  };

  bindEvents = () => {
    window.addEventListener('resize', _.debounce(this.onResize, 200));
    this.onOrientationChange();
  };

  setContentSize = () => {
    if (helpers.isTouchEnabled()) {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      const vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  };

  onResize = () => {
    if (window.innerWidth !== this.windowWidth) {
      // Update the window width for next time
      this.windowWidth = window.innerWidth;
      this.setContentSize();
    }
  };

  onOrientationChange = () => {
    // We need to adjust or clear the values when the device changes
    window.addEventListener(
      'orientationchange',
      () => {
        this.setContentSize();
      },
      false
    );
  };

  render() {
    return <div />;
  }
}
