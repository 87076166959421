/**
 * Check if touch is enabled - https://www.geeksforgeeks.org/how-to-detect-touch-screen-device-using-javascript/
 */

const isTouchEnabled = () => {
  if (typeof window !== 'undefined') {
    return (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }
};

export default isTouchEnabled;
