import React, { Component } from 'react';
import styled from 'styled-components';
import anime from 'animejs';
import ScrollMagic from 'scrollmagic';
import VisuallyHidden from '../visually-hidden';
import * as vars from '../../styles/vars';

const initialShapePath =
  'M39.7696868,9.95431507 C39.2675294,9.95431507 38.8448136,10.1616779 38.543923,10.49526 C38.1901558,10.8874637 38,11.4374774 38,12.0249259 C38,12.5464231 38.1995366,13.0213471 38.5265692,13.3773569 C38.8921776,13.7753604 39.4169251,14.0249259 40,14.0249259 C40.5522847,14.0249259 41.0522847,13.8010682 41.4142136,13.4391394 C41.7761424,13.0772106 42,12.5772106 42,12.0249259 C42,11.6040458 41.8362014,11.2111995 41.584163,10.8847166 C41.1594324,10.3345334 40.4790202,9.95431507 39.7696868,9.95431507 Z';

export default class BackToTop extends Component {
  constructor() {
    super();

    this.shapeRef = React.createRef();
    this.arrowRef = React.createRef();

    this.shapePathData = [
      {
        value:
          'M37.4199219,163 C28.6830733,163 21.342821,166.63027 16.1124301,172.428939 C10.5689819,178.574678 7.39257812,187.141668 7.39257812,196.398438 C7.39257812,204.902329 10.6478475,212.645943 15.9803958,218.450987 C21.9404786,224.93917 30.495307,229.005859 40,229.005859 C49.0042909,229.005859 57.1561464,225.356149 63.0569291,219.455367 C68.9577119,213.554584 72.6074219,205.402728 72.6074219,196.398438 C72.6074219,189.414969 70.0240899,182.83335 65.8225267,177.390773 C59.1740758,168.778571 48.4706796,163 37.4199219,163 Z',
        duration: 1400,
      },
    ];
  }

  componentDidMount = () => {
    this.setupAnimation();
  };

  // We need this cos Anime flickers when you reversse
  // https://github.com/juliangarnier/anime/issues/577
  // https://codepen.io/slavanossar/pen/PoweKmP?editors=0010
  handleTlDirection = () => {
    if (this.tl.began) {
      this.tl.reverse();

      if (this.tl.progress === 100 && this.tl.direction === 'reverse') {
        this.tl.completed = false;
      }
    }

    if (this.tl.paused) {
      this.tl.play();
    }
  };

  setupAnimation = () => {
    this.controller = new ScrollMagic.Controller({
      globalSceneOptions: {
        triggerHook: 'onLeave',
      },
    });

    this.tl = anime.timeline({
      targets: this.shapeRef.current,
      autoplay: false,
    });

    this.tl.add({
      opacity: [0, 1],
      duration: 300,
      easing: 'easeInOutQuad',
    });

    this.tl.add(
      {
        d: this.shapePathData,
        easing: 'easeInOutElastic(1, .5)',
      },
      '-=800'
    );

    this.tl.add(
      {
        targets: this.arrowRef.current,
        opacity: [0, 1],
        translateY: ['-10px', 0],
        duration: 300,
        easing: 'easeInOutQuad',
      },
      '-=500'
    );

    this.tl.add(
      {
        stroke: ['rgba(255,255,255,0)', 'rgba(255,255,255,1)'],
        duration: 100,
      },
      '-=300'
    );

    new ScrollMagic.Scene({
      triggerElement: '#top',
    })
      .offset(1800)
      .on('enter leave', e => {
        this.handleTlDirection(e.scrollDirection);
      })

      // .addIndicators()
      // .on('progress', function(event) {
      //   tl.seek(tl.duration * event.progress);
      // })
      .addTo(this.controller);
  };

  render() {
    return (
      <Wrapper href="#top" data-scroll-to-anchors data-hover>
        <VisuallyHidden>Back to top</VisuallyHidden>
        <ArrowWrapper>
          <ArrowInner ref={this.arrowRef}>
            {/* Top */}
            <Arrow xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 14">
              <path
                stroke="#ffffff"
                strokeWidth="3"
                d="M2.188 10.813l9-8.626m.187.188l8.625 9"
                fill="none"
                fillRule="evenodd"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </Arrow>
          </ArrowInner>
        </ArrowWrapper>
        <Circle xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 260">
          <path
            d={initialShapePath}
            stroke="transparent"
            strokeWidth="2"
            fill="#9134FC"
            fillRule="evenodd"
            ref={this.shapeRef}
            className="shape"
          />
        </Circle>
      </Wrapper>
    );
  }
}

const Wrapper = styled.a`
  position: fixed;
  display: block;
  bottom: -35px;
  right: 0;
  width: 80px;
  height: 260px;
  transform: scale(0.8);

  ${vars.breakpoint.md`
    bottom: 20px;
    right: 20px;
    transform: scale(1)
  `}
`;

const Circle = styled.svg`
  width: 80px;
  height: 260px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

const Arrow = styled.svg`
  /* transform: rotate(90deg); */
`;

const ArrowWrapper = styled.div`
  position: absolute;
  width: 24px;
  bottom: 36px;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 2;
`;

const ArrowInner = styled.div`
  /* outline: 2px solid red; */
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
`;
