/**
 * These values control CSS and JS breakpoints
 */

const bp = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
};

export default bp;
