/**
 * Used for global styles, all other styles are declared at
 * the component level
 */

import { createGlobalStyle } from 'styled-components';

import 'normalize.css';
import baseStyles from './base';

const GlobalCSS = createGlobalStyle`
  ${baseStyles}
`;

export default GlobalCSS;
