import React, { Component } from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';
import styled from 'styled-components';
import ScrollMagic from 'scrollmagic';
import FancyLink from '../fancy-link';
import { navigate } from '@reach/router';
import { TweenLite, ScrollToPlugin } from 'gsap/all';
import { window } from 'browser-monads';
import * as vars from '../../styles/vars';
import * as helpers from '../../helpers';
const Jelly = typeof document !== `undefined` ? require('jelly.js') : null; // https://github.com/lmgonzalves/jelly

// Without this line, ScrollToPlugin may get dropped by gatsby build...
/* eslint-disable-next-line */
const plugins = [ScrollToPlugin];

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.scrollOffset = 1;
    this.initialDelay = 3400; //Initial delay to let the title come in

    this.logoRef = React.createRef();
    this.logoSvgRef = React.createRef();
    this.navListRef = React.createRef();
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller();
    this.anchorLinks = document.querySelectorAll('[data-scroll-to-anchors]');
    this.animateIntro();

    if (typeof document !== 'undefined') {
      this.createScenesAndBindEvents();
    }

    this.createJellyLogo();
  }

  createScenesAndBindEvents = () => {
    [...this.anchorLinks].forEach((el, i) => {
      const id = el.getAttribute('href');
      if (document.querySelector(id) !== null) {
        el.addEventListener('click', e => {
          e.preventDefault();
          this.scrollToAnchors(document.querySelector(id), id);

          // If supported by the browser we can also update the URL
          /* eslint-disable-next-line no-restricted-globals*/
          // if (window.history && window.history.pushState) {
          //   console.log('hey now');
          //   history.pushState('', document.title, id);
          // }
        });
      }
    });
  };

  scrollToAnchors = (target, id) => {
    const elOffset = window.pageYOffset + target.getBoundingClientRect().top;

    TweenLite.to(window, 0.8, {
      scrollTo: {
        y: elOffset,
        autoKill: false, // allows user to kill scroll action smoothly
      },
      ease: 'Cubic.easeInOut',
      onComplete: () => {
        navigate(id);
      },
    });
  };

  createJellyLogo = () => {
    var options = {
      paths: '#logo-path', // Shape we want to draw
      pointsNumber: 14, // Number of points
      // maxDistance: 10, // Max distance among points
      maxDistance: 40,
      mouseIncidence: 20,
      maxIncidence: 20,
      color: vars.colors.brand,
      // maxIncidence: 20,
      // intensity: 0.5,
      centroid: '.centroid-text', // Element to move accordingly with the centroid of the shape
      // debug: true, // Uncomment this to see the points
    };

    /* Initializing jelly */
    new Jelly('.js-jelly-canvas', options);
  };

  animateIntro = () => {
    anime.set({
      targets: this.logoRef.current,
      transformOrigin: '50% 50%',
    });

    // Logo
    const tl = anime.timeline({
      targets:
        helpers.isTouchEnabled() || helpers.isRetinaScreen
          ? this.logoSvgRef.current
          : this.logoRef.current,
      easing: 'linear',
    });

    // Add children
    tl.add({
      opacity: [0, 1],
      duration: 200,
      delay: this.initialDelay, // Initial delay to let the title come in
    }).add(
      {
        scale: [1.3, 1],
        // easing: 'easeInOutElastic(2, .5)',
        easing: 'spring(1, 100, 10, 20)',
        duration: 500,
      },
      '-=200'
    );

    // Nav
    anime({
      targets: this.navListRef.current.querySelectorAll('li'),
      opacity: [0, 1],
      duration: 80,
      // easing: 'easeInOutQuart',
      easing: 'spring(1, 100, 10, 20)',
      translateY: [-10, 0],
      delay: anime.stagger(100, { start: this.initialDelay + 600 }),
    });
  };

  render() {
    return (
      <header>
        <Wrapper>
          <Logo>
            <LogoCanvas
              ref={this.logoRef}
              className="js-jelly-canvas"
            ></LogoCanvas>
            <LogoTextContainer>
              <LogoText className="centroid-text">
                <span>mb</span>
              </LogoText>
            </LogoTextContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 180 180"
              width="180"
              height="180"
              ref={this.logoSvgRef}
            >
              <path
                id="logo-path"
                d="M92.7803049,148 C124.375914,148 149.989217,122.032515 149.989217,90 C149.989217,74.6784004 150.675241,53.9979077 141.103634,43.6294148 C130.664103,32.3207382 109.263293,32 92.7803049,32 C61.1846954,32 30,57.9674845 30,90 C30,106.019617 33.3303438,121.320125 45.0800889,131.816582 C56.8249067,142.308637 76.9858133,148 92.7803049,148 Z"
                fill="#9134FC"
                fillRule="evenodd"
              />
            </svg>
          </Logo>
          <Nav aria-label="main-nav">
            <NavList ref={this.navListRef}>
              <NavListItem>
                <FancyLink
                  href="#testimonials"
                  distanceThreshold={{ min: 0, max: 100 }}
                  data-scroll-to-anchors
                  data-hover
                >
                  Testimonials
                </FancyLink>
              </NavListItem>
              <NavListItem>
                <FancyLink
                  href="#about"
                  distanceThreshold={{ min: 0, max: 100 }}
                  data-scroll-to-anchors
                  data-hover
                >
                  About
                </FancyLink>
              </NavListItem>
              <NavListItem>
                <FancyLink
                  href="#work"
                  distanceThreshold={{ min: 0, max: 100 }}
                  data-scroll-to-anchors
                  data-hover
                >
                  Work
                </FancyLink>
              </NavListItem>
              <NavListItem>
                <FancyLink
                  href="#contact"
                  distanceThreshold={{ min: 0, max: 100 }}
                  data-scroll-to-anchors
                  data-hover
                >
                  Contact
                </FancyLink>
              </NavListItem>
            </NavList>
          </Nav>
        </Wrapper>
      </header>
    );
  }
}

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: ${vars.global.siteWidth};
  padding: 25px;
  position: absolute;
  left: 0;
  right: 0;

  ${vars.breakpoint.md`
    padding: 30px;
  `}

  ${vars.breakpoint.xl`
    padding: 50px 40px;
  `}

  ${vars.breakpoint.xxl`
    padding: 60px 30px;
  `}
`;

const Logo = styled.div`
  color: #fff;
  width: 140px;
  height: 135px;
  position: relative;
  margin-left: -35px;
  margin-top: -55px;

  ${vars.breakpoint.md`
    margin-top: -60px;
  `}

  ${vars.breakpoint.lg`
    margin-top: -40px;
  `}

  svg {
    position: relative;
    z-index: 200;
    display: none;

    /* Touch screens */
    @media (pointer: none), (pointer: coarse) {
      display: block;
    }

    /* Retina */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      display: block;
    }
  }
`;

const LogoTextContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 300;
`;

const LogoText = styled.div`
  font-size: 50px;
  letter-spacing: -2px;

  span {
    display: block;
    margin-top: -4px;
    margin-left: -4px;

    ${vars.browserHack.firefox`
       margin-top: 4px;
      `}

    ${vars.browserHack.edge`
      margin-top: 4px;
    `}
  }
`;

const LogoCanvas = styled.canvas`
  width: 180px;
  height: 180px;
  position: absolute;
  display: block;

  /* Touch screens */
  @media (pointer: none), (pointer: coarse) {
    display: none;
  }

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    display: none;
  }
`;

const Nav = styled.nav`
  margin-left: auto;
  margin-top: -5px;

  ${vars.breakpoint.md`;
    margin-top: 0;
  `}

  ${vars.breakpoint.lg`
    display: block;
  `}
`;

const NavList = styled.ul`
  text-align: right;

  ${vars.breakpoint.lg`
    display: flex;
  `}
`;

const NavListItem = styled.li`
  ${vars.breakpoint.lg`
     margin: 0 20px;
  `}

  a {
    font-size: 20px;
    color: ${vars.colors.black};
    text-decoration: none;
    line-height: 1.3;

    ${vars.breakpoint.md`
      font-size: 22px;
      line-height: 1.4;

      ${vars.browserHack.firefox`
        line-height: 1.1;
      `}

      ${vars.browserHack.edge`
        line-height: 1.1;
      `}
    `}

    ${vars.breakpoint.lg`
      font-size: 26px
    `}

    ${vars.breakpoint.xl`
      font-size: 30px
    `}

    &:hover {
      color: ${vars.colors.brand};

      span {
        background-color: ${vars.colors.brand};
      }
    }

    span {
      transition: background-color 0.3s ease-in-out;
      height: 3px;
      background-color: ${vars.colors.greyLighter};
      display: none;

      ${vars.breakpoint.lg`
        display: block;
      `}
    }
  }
`;

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};
