/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GlobalCSS from '../styles/';
import ScrollMagic from 'scrollmagic';
import { TimelineMax } from 'gsap/all';
import { graphql, StaticQuery } from 'gatsby';
import Header from './header';
import Loading from './loading';
import CustomCursor from './custom-cursor';
import SetVhUnitsOnTouchDevices from './set-vh-units-on-touch-devices';
import { colors } from '../styles/vars';
import * as helpers from '../helpers';
import BackToTop from './back-to-top';
import SEO from './seo';

require('animation.gsap');
require('debug.addIndicators');

export default class Layout extends Component {
  constructor() {
    super();

    this.state = {
      loaded: false,
    };

    this.getScrollAnimationsOffset();
  }

  componentDidMount() {
    this.controller = new ScrollMagic.Controller({
      globalSceneOptions: {
        triggerHook: 'onEnter',
      },
    });

    if (process.env.NODE_ENV !== 'development') {
      console.log(
        "%c OH HAI, I'm glad you came to peep under the hood. The site was built using GatsbyJS, Styled Components, GSAP and ScrollMagic. Oh and of course built with ❤️ as everyone and thier mum likes to point out, I mean, if you didn't build it with love then surely you're in the wrong game. WISE UP FELLA/LADY.",
        `background-color: ${colors.brand}; color: #ffffff; padding: 10px; line-height: 30px; font-size: 14px; border-radius: 8px`
      );
    }
  }

  getScrollAnimationsOffset = () => {
    this.scrollAnimationsOffset = helpers.breakpoints.lg() ? 200 : 80;
  };

  animateItemsUp = element => {
    [...element].forEach(item => {
      new ScrollMagic.Scene({
        triggerElement: item,
      })
        .setClassToggle(item, 'has-animated')
        .offset(150)
        .reverse(false)
        .addTo(this.controller);
    });
  };

  scrollItemsUp = element => {
    [...element].forEach(item => {
      let genericAppearAnimation = new TimelineMax().fromTo(
        item,
        1,
        {
          y: 30,
          opacity: 0,
          scale: 0.98,
        },
        {
          y: 0,
          opacity: 1,
          scale: 1,
        }
      );

      new ScrollMagic.Scene({
        duration: 200,
        triggerElement: item,
      })
        .offset(this.scrollAnimationsOffset)
        .setTween(genericAppearAnimation)
        // .addIndicators({ name: item.getAttribute('class') })
        // .on('progress', function(event) {
        //   tl.seek(tl.duration * event.progress);
        // })
        .addTo(this.controller);
    });
  };

  handleLoader = () => {
    this.setState({
      loaded: true,
    });

    this.animationElement = document.querySelectorAll('[data-anim-in]');
    this.scrollItemsUp(this.animationElement);
  };

  render() {
    return (
      <>
        <StaticQuery
          query={graphql`
            query SiteTitleQuery {
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
          render={data => {
            if (!this.state.loaded) {
              return (
                <Loading
                  handleLoader={() => this.handleLoader()}
                  loaded={this.state.loaded}
                />
              );
            }

            return (
              <>
                <Container id="top">
                  <Header siteTitle={data.site.siteMetadata.title} />
                  <div>
                    <Main>{this.props.children}</Main>
                  </div>
                  <aside>
                    <BackToTop />
                  </aside>
                  <GlobalCSS />
                  <CustomCursor />
                  <SetVhUnitsOnTouchDevices />
                </Container>
              </>
            );
          }}
        ></StaticQuery>
        <SEO title="Freelance Front-end Developer based in Melbourne - Mike Barker" />
      </>
    );
  }
}

const Main = styled.main``;

const Container = styled.div`
  overflow: hidden;
  min-height: 100%;
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
