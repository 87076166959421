// Can't be used with Styled Components

import bowser from 'bowser'; // https://github.com/lancedikson/bowser/

let browser;

if (typeof window !== 'undefined') {
  browser = bowser.getParser(window.navigator.userAgent);
} else {
  browser = bowser.getParser('all');
}

const isIE11 = browser.satisfies({
  windows: {
    'internet explorer': '<=11',
  },
});

const isEdge = browser.satisfies({
  windows: {
    'Microsoft Edge': '>11',
  },
});

const isSafari = browser.satisfies({
  macos: {
    safari: '>4',
  },
});

const isFirefox = browser.satisfies({
  macos: {
    firefox: '>20',
  },
});

const isSafariIos = browser.satisfies({
  mobile: {
    safari: '>7',
  },
  tablet: {
    safari: '>7',
  },
});

export { isIE11, isEdge, isSafari, isFirefox, isSafariIos };
