import React, { Component } from 'react';
import styled from 'styled-components';
import TweenMax from 'gsap/TweenMax';
import * as vars from '../../styles/vars';
import * as helpers from '../../helpers';
import { isSafari } from '../../helpers/browserDetection';

export default class CursorFx extends Component {
  componentDidMount = () => {
    if (!helpers.isTouchEnabled()) {
      this.initCursor();
      this.initHovers();
    }
  };

  initCursor() {
    const { Back } = window;
    this.outerCursor = document.querySelector('.circle-cursor--circle');
    this.innerCursor = document.querySelector('.circle-cursor--dot');
    this.outerCursorBox = this.outerCursor.getBoundingClientRect();
    this.outerCursorSpeed = 0;
    this.easing = Back.easeOut.config(1.7);
    this.clientX = -100;
    this.clientY = -100;
    this.showCursor = false;

    const unveilCursor = () => {
      TweenMax.set(this.innerCursor, {
        x: this.clientX,
        y: this.clientY,
      });
      TweenMax.set(this.outerCursor, {
        x: this.clientX - this.outerCursorBox.width / 2,
        y: this.clientY - this.outerCursorBox.height / 2,
      });
      setTimeout(() => {
        this.outerCursorSpeed = 0.4;
      }, 100);
      this.showCursor = true;
    };
    document.addEventListener('mousemove', unveilCursor);

    document.addEventListener('mousemove', e => {
      this.clientX = e.clientX;
      this.clientY = e.clientY;
    });

    const render = () => {
      TweenMax.set(this.innerCursor, {
        x: this.clientX,
        y: this.clientY,
      });
      if (!this.isStuck) {
        TweenMax.to(this.outerCursor, this.outerCursorSpeed, {
          x: this.clientX - this.outerCursorBox.width / 2,
          y: this.clientY - this.outerCursorBox.height / 2,
        });
      }
      if (this.showCursor) {
        document.removeEventListener('mousemove', unveilCursor);
      }
      requestAnimationFrame(render);
    };
    requestAnimationFrame(render);
  }

  initHovers() {
    const handleMouseEnter = e => {
      this.isStuck = false;
      // const target = e.currentTarget;
      // const box = target.getBoundingClientRect();
      this.outerCursorOriginals = {
        width: this.outerCursorBox.width,
        height: this.outerCursorBox.height,
      };
      TweenMax.to(this.outerCursor, 0.2, {
        // x: box.left + box.width / 2 - 14, // use if you want to use isStuck
        // y: box.top + box.height / 2 - 14, // use if you want to use isStuck
        scale: isSafari ? 1 : 3, // Safari can't handle scaling borders
        borderColor: `${vars.colors.brand}`,
        borderWidth: isSafari ? 2 : 1,
      });
    };

    const handleMouseLeave = () => {
      this.isStuck = false;
      TweenMax.to(this.outerCursor, 0.2, {
        width: this.outerCursorOriginals.width,
        height: this.outerCursorOriginals.height,
        scale: 1,
        borderColor: `${vars.colors.greyLighter}`,
        borderWidth: 2,
      });
    };

    const linkItems = document.querySelectorAll('[data-hover]');
    linkItems.forEach(item => {
      item.addEventListener('mouseenter', handleMouseEnter);
      item.addEventListener('mouseleave', handleMouseLeave);
    });

    const mainNavHoverTween = TweenMax.to(this.outerCursor, 0.3, {
      backgroundColor: '#ffffff',
      ease: this.easing,
      paused: true,
    });

    const mainNavMouseEnter = () => {
      this.outerCursorSpeed = 0;
      TweenMax.set(this.innerCursor, { opacity: 0 });
      mainNavHoverTween.play();
    };

    const mainNavMouseLeave = () => {
      this.outerCursorSpeed = 0.2;
      TweenMax.set(this.innerCursor, { opacity: 1 });
      mainNavHoverTween.reverse();
    };

    const mainNavLinks = document.querySelectorAll('.content--fixed a');
    mainNavLinks.forEach(item => {
      item.addEventListener('mouseenter', mainNavMouseEnter);
      item.addEventListener('mouseleave', mainNavMouseLeave);
    });
  }

  render() {
    return (
      <Cursor>
        <CursorCircle className="circle-cursor--circle"></CursorCircle>
        <CursorDot className="circle-cursor--dot"></CursorDot>
      </Cursor>
    );
  }
}

const Cursor = styled.div`
  /* This is to hide the custom cursor on touch screens */
  @media (pointer: none), (pointer: coarse) {
    display: none;
  }
`;

const CursorCircle = styled.div`
  z-index: 9999;
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  mix-blend-mode: difference;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 2px solid ${vars.colors.greyLighter};
  /* transition: border-color 0.2s ease-in-out; */
  transform-origin: 50% 50%;
`;

const CursorDot = styled.div`
  z-index: 9999;
  pointer-events: none;
  position: fixed;
  left: -3px;
  top: -3px;
  mix-blend-mode: difference;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  transition: background 0.2s ease-in-out;
  background: ${vars.colors.black};
`;
