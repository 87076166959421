import bp from './bp';
import { css } from 'styled-components';

const breakpoint = {
  xxs: (...args) =>
    css`
      @media (max-width: 380px) {
        ${css(...args)};
      }
    `,
  xs: (...args) =>
    css`
      @media (max-width: ${bp.sm}) {
        ${css(...args)};
      }
    `,
  sm: (...args) =>
    css`
      @media (min-width: ${bp.sm}) {
        ${css(...args)};
      }
    `,
  md: (...args) =>
    css`
      @media (min-width: ${bp.md}) {
        ${css(...args)};
      }
    `,
  lg: (...args) =>
    css`
      @media (min-width: ${bp.lg}) {
        ${css(...args)};
      }
    `,
  xl: (...args) =>
    css`
      @media (min-width: ${bp.xl}) {
        ${css(...args)};
      }
    `,
  xxl: (...args) =>
    css`
      @media (min-width: ${bp.xxl}) {
        ${css(...args)};
      }
    `,
  landscapeMobile: (...args) =>
    css`
      @media (max-height: 600px) {
        ${css(...args)};
      }
    `,
};

export default breakpoint;
