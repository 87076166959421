import * as vars from './vars';
import * as helpers from '../helpers';

import typography from './typography';
export default `

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

  ::selection {
    color: ${vars.colors.white};
    background: ${vars.colors.black};
  }

  ::-moz-selection {
    color: ${vars.colors.white};
    background: ${vars.colors.black};
  }

  html,
  body {
    -moz-osx-font-smoothing: auto;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden;
    -webkit-font-smoothing: antialiased !important;
    color: ${vars.global.baseFontColor};
    font-family: ${vars.global.baseFontFamily};
    font-size: ${vars.global.baseFontSize};
    font-weight: normal;
    line-height: 1.5;
    margin: 0;
    height: 100%;
    min-width: 320px;
    position: relative;
    text-rendering: optimizeLegibility !important;
    width: 100%;
    z-index: 0;
    padding: 0;
    // scroll-behavior: smooth;
    
  }
  
  body {
    background-color: transparent;
    cursor: ${helpers.isTouchEnabled() ? 'default' : 'none'};
    // overflow-x: hidden;

    /**
     * @section Breakpoints
     * These values will not show up in content, but can be queried by JavaScript to know which breakpoint is active. Add or remove as many breakpoints as you like.
     */

    &:before {
      content: "xs";
      display: ${process.env.NODE_ENV === 'development' ? 'block' : 'none'};
      visibility: ${
        process.env.NODE_ENV === 'development' ? 'visible' : 'hidden'
      };
      background-color: #000;
      position: fixed;
      bottom: 0;
      right: 0;
      font-size: 12px;
      padding: 5px 10px;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      z-index: 2000;
    }

    @media (min-width: ${vars.bp.sm}) {
      &:before {
        content: "sm";
      }
    }

    @media (min-width: ${vars.bp.md}) {
      &:before {
        content: "md";
      }
    }

    @media (min-width: ${vars.bp.lg}) {
      &:before {
        content: "lg";
      }
    }

     @media (min-width: ${vars.bp.xl}) {
      &:before {
        content: "xl";
      }
    }

     @media (min-width: ${vars.bp.xxl}) {
      &:before {
        content: "xxl";
      }
    }
  }

  a[href*="mailto:"] {
    word-wrap: break-word;
  }

  a:hover, button:hover {
    cursor: ${helpers.isTouchEnabled() ? 'default' : 'none'};
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: ${vars.global.baseFontSize};
    outline: none;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: .8em;
    margin-top: 0;
  }

  h1 {
    ${typography.headingXLarge}
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    ${typography.headingBase}
  }

  {/* Reset Lists */}
  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  {/* Images */}
  figure {
    margin: 0;
  }

  img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
  }

  a {
    color: ${vars.colors.black};
  }

  p {
    margin-top: 0;
    margin-bottom: ${vars.spacing.base};

    b,
    strong {
      font-weight: bold;
    }

    i,
    em {
      font-style: italic;
    }
  }

  svg {
    display: inline-block;
    vertical-align: top;
  }

  button {
    background: none;
    border: none;
    padding: 0;
  }


  {/* Default Transitions */}
  a,
  input,
  select,
  textarea,
  button {
    -webkit-tap-highlight-color: ${vars.global.tapHighlightColor};
    transition-duration: 0.5s;
    transition-property: background-color, color, opacity, border, border-color, background-position, outline, box-shadow, border-bottom, text-decoration, left, top, transform;
    transition-timing-function: ${vars.global.easing};
    transform-origin: center center;
  }

  // Flickity

  .flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  /* overflow: hidden; */
  position: relative;
  height: 100%;
  transition: height 0.6s ease-in-out;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  /* background: hsla(0, 0%, 100%, 0.75); */
  border: none;
  color: #9134fc;
}

.flickity-button:hover {
  /* background: white; */
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: transparent;
  stroke: currentColor;
  stroke-width: 6px;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}
.flickity-prev-next-button.next {
  right: 10px;
}

.flickity-prev-next-button.next svg path {
  transform: rotate(180deg) !important;
  transform-origin: 50% 50%;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 100%;
  height: 100%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}
`;
